import React, { useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceRbpj'
import { toast } from 'react-toastify'
import Helpers from '@components/Helpers/Helpers';
import Controllers from '@components/Controllers/Controllers';
import { CheckBox, Select } from '@components/Form/Form'
import { SwitchToggle, Input, Radio, FormCustom } from '@components/Form/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

/**
 * Inventory
 * @version 0.9.96
 */
const Inventory = ({ showFirstLevel }) => {
    const [detailsMass, setDetailsMass] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [formMass, setFormMass] = useState({
        p_file_excel: ''
    })

    const [modalInventoryShow, setModalInventoryShow] = useState(false)
    const [formInventory, setFormInventory] = useState({
        inventory: ''
    })
    const [detailInventory, setDetailInventory] = useState({})
    const [loadInventory, setLoadInventory] = useState(false)


    const [modalMovesShow, setModalMovesShow] = useState(false)
    const [detailMoves, setDetailMoves] = useState({})
    const [loadMoves, setLoadMoves] = useState(false)
    const [formMoves, setFormMoves] = useState({
        inventory: '',
        type: '',
        id_warehouse: ''
    })
    return <>
        <MaintenanceCustom title={<>
            <i className="fa fa-warehouse text-secondary mr-2"></i> Inventario
        </>}
            prependCard={({ setReload }) => <>
                <a href={Helpers.config.resourcesUrl({
                    url: '/product_history/download_inventory?jwt=' + Helpers.localStorage.getJWT()
                })} download="Descarga de inventario" className="btn btn-sm btn-primary mr-1">Descargar inventario
                </a>

                <a href={Helpers.config.resourcesUrl({
                    url: '/product_history/download_inventory/true?jwt=' + Helpers.localStorage.getJWT()
                })} download="Productos Agotados" className="btn btn-sm btn-danger mr-1">Productos Agotados
                </a>

                {/* Modal de carga de inventario */}
                <Button variant="warning" onClick={_ => {
                    setDetailInventory({})
                    setModalInventoryShow(true)
                }} className="mr-3 btn-sm">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Inventario
                </Button>
                <Modal show={modalInventoryShow} size="lg" onHide={_ => setModalInventoryShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga de Productos</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalInventoryShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Plantilla de inventario
                            <a href={Helpers.config.resourcesUrl({
                                url: '/excel/inventory.xlsx'
                            })} download="plantilla_carga_inventario" className="ml-2">
                                <Button size="xs" variant="success"><i className="fa fa-file-excel"></i></Button>
                            </a>
                        </strong>
                        <p>Las columnas que contengan existencias están sujetas a las siguientes validaciones:</p>
                        <ol>
                            <li>Todas las columnas deben ser enteros positivos.</li>
                            <li>El número cero vacía la existencia de ese producto en la columna correspondiente.</li>
                            <li>Si la columna está en blanco será ignorada y no afectará el inventario.</li>
                        </ol>
                        <p>Se debe tener en cuenta que el archivo Excel ignora las columnas ocultas, las fórmulas y los macros, por lo que se recomienda subir un archivo plano y sin espacios entre las filas.</p>
                        <FormCustom loadInventory={loadInventory} onSubmit={_ => {
                            setDetailInventory({})
                            setLoadInventory(true)
                            Controllers.product.updateExcelInventory(Helpers.formData.generate({
                                data: formInventory,
                                file: [
                                    { name: 'inventory', value: formInventory.inventory[0] }
                                ]
                            })).then(res => {
                                setDetailInventory(res)
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setLoadInventory(false)
                                setReload(true)
                            })
                        }}>
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    setFormInventory({
                                        inventory: e.currentTarget.files.length > 0 ? e.currentTarget.files : ''
                                    })
                                }}
                            />
                        </FormCustom>
                        {detailInventory?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailInventory.message}</strong></div>
                                <div className="text-success">Productos actualizados: {detailInventory.data.qty_updated}</div>
                                {detailInventory?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Productos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailInventory?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>{r}</strong>
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal>
            </>}
            removeId
            removeEnabled
            removeDescription
            actionsCustom
            actions={({ updateButton, deleteButton, data }) => {
                return ''
            }}
            namePlural="Inventario" nameSingle="Inventario" separator="El" module="inventory"
            tableFieldsAdd={[
                {
                    name: 'id_product', nameSelect: 'id_product', text: '#ID', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'key_product', nameSelect: 'key_product', text: 'SKU', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'id_item_global_product', nameSelect: 'id_item_global_product', text: 'Item Global', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true
                },
                {
                    name: 'description_product', nameSelect: 'description_product', text: 'Descripción', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true
                },
                {
                    name: 'interlomas', nameSelect: 'interlomas', text: 'Interlomas', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'artz', nameSelect: 'artz', text: 'Artz', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'tultitlan', nameSelect: 'tultitlan', text: 'Tultitlán', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'satelite', nameSelect: 'satelite', text: 'Satélite', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'andares', nameSelect: 'andares', text: 'Andares', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
                {
                    name: 'cryptex', nameSelect: 'cryptex', text: 'Cryptex', type: 'select',
                    hideOnTable: false,
                    hideOnForm: true,
                    align: "center"
                },
            ]}
            optionsView={
                {
                    removeEnabled: true,
                    removeDescription: true,
                    beforeRenderForm: ({ ready, data, setState, setStateDynamic }) => {

                        let promises = [
                            Controllers.product_history.inventory(),
                        ]

                        Promise.all(promises).then(res => {
                            setState({
                                data: res[0].data,
                            })
                            setStateDynamic({
                                data: res[0].data,
                            })
                            ready()
                        })
                    },
                }

            }
        />
    </>
}

Inventory.defaultProps = {
    showFirstLevel: true
}

export default Inventory;
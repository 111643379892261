import React, { useEffect, useState } from 'react';
import MaintenanceCustom from '@views/Maintenance/MaintenanceRbpj'
import Helpers from '@components/Helpers/Helpers';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Input, FormCustom, Select } from '@components/Form/Form'
import Controllers from '@components/Controllers/Controllers';
import { toast } from 'react-toastify'

/**
 * Transfers
 * @version 0.9.99
 * Transfers betwen warehouses
 */
const Transfers = _ => {

    const [detailsForm, setDetailsForm] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [worker, setWorker] = useState({})
    const [warehouse, setWarehouse] = useState(false)
    const [formDataTransfer, setFormDataTransfer] = useState({
        transfer: '',
        origin_id_warehouse: '',
        destination_id_warehouse: '',
    })

    const [transferData, setTransferData] = useState({
        id_transfer: 0,
    })

    //TODO: Corrige esto con la base de datos
    function warehouseText(idWarehouse) {
        if (idWarehouse == 1) {
            return "Interlomas"
        } else if (idWarehouse == 2) {
            return "Artz"
        } else if (idWarehouse == 3) {
            return "Tultitlan"
        } else if (idWarehouse == 4) {
            return "Satélite"
        } else if (idWarehouse == 5) {
            return "Andares"
        } else if (idWarehouse == 6) {
            return "Cryptex"
        }
    }

    return <>
        <MaintenanceCustom title={<>
            <i className="fa fa-warehouse text-secondary mr-2"></i> Traspasos
        </>}
            removeId
            removeEnabled
            removeDescription
            namePlural="Traspasos" nameSingle="Traspaso" separator="El" module="transfers"
            actionsCustom
            actions={({ dataButtons, handleGet, handleShowConfirm, setOptionsConfirm, data, setReload, setLoadSubmitConfirm, setShowConfirm, setModalShowAdd, setModalBodyAdd }) => {
                if (worker.ready !== true) {
                    let promises = [
                        Controllers.worker.current(),
                        Controllers.warehouse.get_warehouse()
                    ]

                    Promise.all(promises).then(res => {
                        setWorker({ ready: true, data: res[0].data })
                        setWarehouse(res[1].data)
                    })
                }
                return <>

                    <Button variant="dark" size="sm" className="mr-2" onClick={_ => {
                        setModalBodyAdd(<iframe style={{
                            width: '100%', minHeight: '500px'
                        }} src={Helpers.config.apiUrl({ url: '/transfers/ticket/' + data.id + '?jwt=' + Helpers.localStorage.getJWT() })}></iframe>)
                        setModalShowAdd(true)
                    }}>
                        <i className="fa fa-print"></i>
                    </Button>
                    <Button variant="success" className="mr-2" size="sm" {...dataButtons} onClick={_ => {
                        if (window.confirm("¿Deseas actualizar este traspaso en el inventario?")) {
                            Controllers.transfers.apply({ 'id_transfer': data.id }).then(_ => {
                            }).catch(req => {
                                Helpers.toast.errorRbpj({ message: req.message, toast })
                                // Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setReload(true)
                            })
                        }
                    }} disabled={data.status}>
                        <i className="fa fa-check"></i>
                    </Button>
                    <Button variant="danger" className="mr-2" size="sm" {...dataButtons} onClick={_ => {
                        if (window.confirm("¿Deseas cancelar este traspaso?")) {
                            Controllers.transfers.cancel({ 'id_transfer': data.id }).then(_ => {
                            }).catch(req => {
                                Helpers.promise.catch({ req, toast })
                            }).finally(_ => {
                                setReload(true)
                            })
                        }
                    }} disabled={data.status}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </>
            }}
            prependCard={({ setReload }) => <>
                <Button variant="success" onClick={_ => {
                    setDetailsForm({})
                    setModalShow(true)
                }} className="mr-3 btn-lg">
                    <i className="fas fa-cloud-upload-alt mr-2"></i> Carga Traspasos
                </Button>

                <Modal show={modalShow} size="lg" onHide={_ => setModalShow(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title as="div">Carga de traspaso con excel</Modal.Title>
                        <div className="text-right align-self-center">
                            <svg className="cur-pointer" onClick={_ => setModalShow(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                            </svg>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Formato de traspasos Excel
                            <a href={Helpers.config.resourcesUrl({
                                url: '/excel/traspasos.xlsx'
                            })} download="traspasos" className="ml-2">
                                <Button size="xs" variant="success"><i className="fa fa-file-excel"></i></Button>
                            </a>
                        </strong>
                        <FormCustom loadSubmit={loadSubmit} disablebSubmit={Boolean(detailsForm.message)} onSubmit={_ => {
                            setDetailsForm({})
                            let messages = [];
                            setLoadSubmit(true)

                            // Warehouses validation
                            if (formDataTransfer.origin_id_warehouse == formDataTransfer.destination_id_warehouse) {
                                messages.push("El almacen de origen y destino deben ser diferentes");
                            }

                            if (!formDataTransfer.destination_id_warehouse) {
                                messages.push("Selecciona un almacen de destino")
                            }

                            // File Validation
                            if (!eval(formDataTransfer.transfer)) {
                                messages.push("El archivo Excel debe ser valido")
                            }

                            // Profile Validation
                            if (worker.data.id_profile == 1 && !formDataTransfer.origin_id_warehouse) {
                                messages.push("Selecciona un almacen de origen")
                            }
                            // idProfile 4 warehoyse validation
                            if (worker.data.id_profile == 4 && formDataTransfer.destination_id_warehouse == worker.data.id_warehouse) {
                                messages.push("El almacen de destino debe ser diferente al de tu sucursal")
                            }

                            if (messages.length > 0) {
                                Helpers.toast.errorRbpj({ message: messages, toast })
                                setLoadSubmit(false)
                                return;
                            }

                            Controllers.transfers.create(Helpers.formData.generate({
                                data: formDataTransfer,
                                file: [
                                    { name: 'transfer', value: eval(formDataTransfer.transfer) ? formDataTransfer.transfer[0] : '' }
                                ]
                            })).then(res => {
                                setDetailsForm(res)
                            }).catch(req => {
                                Helpers.toast.errorRbpj({ message: req.message, toast })
                            }).finally(_ => {
                                setLoadSubmit(false)
                                setReload(true)
                            })
                        }}>
                            <Select
                                text="Almacen de origen"
                                classNameParent="col-12 mb-2"
                                required
                                invalid="Campo obligatorio"
                                disabled={worker.data?.id_profile === 1 ? false : true}
                                options={warehouse}
                                onChange={e => {
                                    formDataTransfer.origin_id_warehouse = e.value;
                                }}
                            />
                            <Select
                                text="Almacen de destino"
                                classNameParent="col-12 mb-2"
                                required
                                invalid="Campo obligatorio"
                                options={warehouse}
                                onChange={e => {
                                    formDataTransfer.destination_id_warehouse = e.value;
                                }}
                            />
                            <Input
                                type="file"
                                text="Archivo Excel"
                                classNameParent="col-12 mb-2"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                required
                                invalid="Campo obligatorio"
                                onChange={e => {
                                    formDataTransfer.transfer = e.currentTarget.files.length > 0 ? e.currentTarget.files : '';
                                }}
                            />
                        </FormCustom>
                        {detailsForm?.message ? <>
                            <div className="d-flex flex-column mt-3">
                                <div><strong>{detailsForm.message}</strong></div>
                                <div className="text-success">Productos actualizados: {detailsForm.data.qty_updated}</div>
                                {detailsForm?.data?.errors?.length > 0 ? <>
                                    <div className="text-danger font-weight-bold">Traspasos por corregir:</div>
                                    <div className="d-flex flex-column">
                                        {React.Children.toArray(detailsForm?.data.errors?.map(r => {
                                            return <div className="text-danger">
                                                <strong>Línea #{r.line}</strong> - {r.message}
                                            </div>
                                        }))}
                                    </div>
                                </> : ''}
                            </div>
                        </> : ''}
                    </Modal.Body>
                </Modal>
            </>}
            tableFieldsAdd={[
                {
                    name: 'id', align: 'center', text: '#ID',
                    hideOnForm: true
                },
                {
                    name: 'origin_id_warehouse', align: 'center', text: 'Origen',
                    hideOnForm: true, render: r => {
                        return warehouseText(r.origin_id_warehouse)
                    },
                },
                {
                    name: 'destination_id_warehouse', align: 'center', text: 'Destino',
                    hideOnForm: true, render: r => {
                        return warehouseText(r.destination_id_warehouse)
                    },
                },
                {
                    name: 'status', align: 'center', text: 'Estado',
                    hideOnForm: true, render: r => {
                        if (r.status == 0) {
                            return "En tránsito"
                        } else if (r.status == 1) {
                            return "Aplicado"
                        } else if (r.status == 2) {
                            return "Cancelado"
                        }
                    }
                },
                {
                    name: 'file', align: 'center', text: 'Archivo',
                    hideOnForm: true, render: r => {
                        return <a href={Helpers.config.resourcesUrl({
                            url: `/storage/${r.file}`
                        })} download="traspaso" className="ml-2">
                            Descarga
                        </a>
                    }
                },
                {
                    name: 'created_at', align: 'center', text: 'Creado',
                    hideOnForm: true, render: r => {
                        const fecha = new Date(r.created_at)
                        let año = fecha.getFullYear();
                        let mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // Agregar 1 al mes ya que en JavaScript los meses van de 0 a 11
                        let dia = ('0' + fecha.getDate()).slice(-2);
                        // Obtener los componentes de la hora
                        let horas = ('0' + fecha.getHours()).slice(-2);
                        let minutos = ('0' + fecha.getMinutes()).slice(-2);
                        // Formatear la fecha y la hora
                        let fechaFormateada = año + '-' + mes + '-' + dia + ' ' + horas + ':' + minutos;

                        return fechaFormateada
                    }
                },
            ]}
            optionsView={{
                removeEnabled: true,
                dataStart: [
                    { name: 'pos', value: 0 },
                ],
                inputDescriptionOptions: {
                    icon: '',
                    size: ''
                },
                beforeRenderForm: ({ setState, setStateDynamic, stateDynamic, ready }) => {

                    let promises = [
                        Controllers.warehouse.get_warehouse(),
                    ]

                    Promise.all(promises).then(res => {
                        setState({
                            warehouse: res[0].data,
                        })
                        setStateDynamic({
                            ...stateDynamic,
                            warehouse: res[0].data,
                        })
                        ready()
                    })
                }

            }}
        />
    </>
}

export default Transfers;